// extracted by mini-css-extract-plugin
export var titleContainer = "services-module--titleContainer--HowU3";
export var testimonials = "services-module--testimonials--dqlIp";
export var service = "services-module--service--64T0w";
export var contactButton = "services-module--contactButton--tGWJN";
export var clientDetails = "services-module--clientDetails--1cwKw";
export var client = "services-module--client--ROPKY";
export var flex = "services-module--flex--4Xfgq";
export var spacer = "services-module--spacer--HJc2J";
export var rightColumn = "services-module--rightColumn--wHErG";
export var blueColor = "services-module--blueColor--nagmW";
export var bottomNavigation = "services-module--bottomNavigation--KlKd2";
export var innerSectionHeader = "services-module--innerSectionHeader---340C";
export var serviceTestimonialList = "services-module--serviceTestimonialList--FoUcF";
export var serviceTestimonialItem = "services-module--serviceTestimonialItem--sZcSj";
export var serviceTestimonialAuthor = "services-module--serviceTestimonialAuthor--XFSUL";
export var serviceTestimonialImages = "services-module--serviceTestimonialImages--Xi6Jh";
export var serviceTestimonialQuoteIcon = "services-module--serviceTestimonialQuoteIcon--P5M3h";
export var serviceTestimonialAuthorImage = "services-module--serviceTestimonialAuthorImage--36oGu";
export var serviceTitle = "services-module--serviceTitle--IHWTX";
export var serviceTestimonialText = "services-module--serviceTestimonialText--BsGh0";
export var professionalServiceHeadingWrapper = "services-module--professionalServiceHeadingWrapper--u53fz";
export var professionalServicesListSection = "services-module--professionalServicesListSection--JanL0";
export var professionalServiceHeader = "services-module--professionalServiceHeader--gazOu";
export var ourApproachHeader = "services-module--ourApproachHeader--9kzgB";
export var professionalServicesListSectionHyperlink = "services-module--professionalServicesListSectionHyperlink--L9Lnc";
export var serviceDescription = "services-module--serviceDescription--4sxVv";