import React from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import SEO from 'components/seo'
import { graphql, Link } from 'gatsby'
import SocialLinks from 'components/socialLinks'
import ContactFormButton from 'components/contactFormButton.js'
import WhatIsComponent from 'components/whatIsComponent'
import parse from 'html-react-parser'
import BlockContentComponent from 'components/blockContentComponent'
import CloudNativeClientsComponent from 'components/cloudNativeClientsComponent'
import { jsonId } from 'utils'
import HelmetExport from 'react-helmet'
import {
  pageWrapper,
  leadTextStyle,
  headingColor,
  contentWrapper,
  clientsLogoSection
} from '../styles/cloudNativeSoftwareEngineering.module.css'
import {
  breadcrumbContainer
} from '../styles/professionalServicesChildren.module.css'
import {
  professionalServiceHeadingWrapper,
  professionalServicesListSection,
  professionalServicesListSectionHyperlink,
  professionalServiceHeader,
  ourApproachHeader
} from '../styles/services.module.css'

export const query = graphql`
  query {
    wordPress {
      pages(where: { name: "Professional Services" }) {
        edges {
          node {
            professionalServicesFieldGroup {
              professionalServicePageHeader
              professionalServicePageSubtext
              professionalServicePageCtaText
              ourApproachImage{
                sourceUrl
              }
              ourApproachContent {
                ourApproachLeadText	
                ourApproachSubtext	
                ourApproachDescription
              }
              professionalServicesListHeader
              professionalServicesListSubheader
              professionalServicesListContent {
                professionalServicesHeader	
                professionalServicesSubheader	
                professionalServicesContent	
                professionalServicesCtaText	
                professionalServicesCtaLink
              }
              whyDeimosImage {
                sourceUrl
              }
              whyDeimosHeader
              whyDeimosSubheader
              whyDeimosContent {                
                whyDeimosLeadText
                whyDeimosSubtext
                whyDeimosDescription
              }
             ourCloudNativeClients
              ourCloudNativeClientsImage {
              clientsLogo {
                sourceUrl
              }
             }
            }
            title
          }
        }
      }
      footers {
        edges {
          node {
            footersFieldGroup {
              socialLinks {
                socialTitle
                socialLogo
                socialUrl
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const ProfessionalServicesPage = ({ data, pageContext }) => {
  const pageData = data.wordPress.pages.edges[0].node.professionalServicesFieldGroup
  const ourApproachImage = pageData.ourApproachImage.sourceUrl
  const ourApproachContent = pageData.ourApproachContent
  const professionalServicesListContent = pageData.professionalServicesListContent
  const professionalServicesListHeader = pageData.professionalServicesListHeader
  const professionalServicesListSubheader = pageData.professionalServicesListSubheader
  const whyDeimosSectionImage = pageData.whyDeimosImage.sourceUrl
  const whyDeimosSectionContent = pageData.whyDeimosContent
  const ourCloudNativeClientsLogo = pageData.ourCloudNativeClientsImage
  const ourCloudNativeClientsHeader = pageData.ourCloudNativeClients
  const footerData = data.wordPress.footers.edges[0].node.footersFieldGroup
  const pageTitle = data.wordPress.pages.edges[0].node.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const contextSlug = pageContext.slug
  const {
    breadcrumb: { crumbs }
  } = pageContext

  return (
    <>
      <HelmetExport>
        {jsonId({
          '@context': 'https://schema.org',
          '@type': 'BreadcrumbList',
          'itemListElement': [{
            '@type': 'ListItem',
            'item': siteUrl,
            'name': 'Home',
            'position': 1
          }, {
            '@type': 'ListItem',
            'item': `${siteUrl}/${contextSlug}`,
            'name': `${pageTitle}`,
            'position': 2
          }]
        })}
      </HelmetExport>
      <SEO
        description='Our Professional Services Information'
        title='Professional Services' />

      <div className={pageWrapper}>
        <div className={breadcrumbContainer}>
          <Breadcrumb
            crumbLabel='Professional Services'
            crumbs={crumbs}
            crumbSeparator=' > '
          />
          <div className={professionalServiceHeadingWrapper}>
            <h1>{pageData.professionalServicePageHeader}</h1>
            <p>{pageData.professionalServicePageSubtext}</p>
            <ContactFormButton ctaText={pageData.professionalServicePageCtaText} />
          </div>
        </div>
        <WhatIsComponent
          backgroundColor={'var(--formFields)'}
          image={ourApproachImage}
          items={ourApproachContent}
          professionalServices
          render={(item, index) => {
            const { ourApproachLeadText, ourApproachSubtext, ourApproachDescription } = item
            return (
              <div
                className={contentWrapper}
                key={index.toString()}>
                <span className={leadTextStyle}>{ourApproachLeadText}</span>
                <h2 className={ourApproachHeader}>{ourApproachSubtext}</h2>
                <span>{parse(ourApproachDescription)}</span>
              </div>
            )
          }}
        />
        <div>
          <BlockContentComponent
            backgroundColor={'var(--textColor)'}
            items={professionalServicesListContent}
            leadText={professionalServicesListHeader}
            professionalServices
            render={(item, index) => {
              const { professionalServicesHeader, professionalServicesContent, professionalServicesCtaText, professionalServicesCtaLink, professionalServicesSubheader } = item
              return (
                <div
                  className={professionalServicesListSection}
                  key={index.toString()}>
                  <div className={professionalServiceHeader}>
                    <h3>{professionalServicesHeader}</h3>
                    <p>{professionalServicesSubheader}</p>
                  </div>
                  <div>{parse(professionalServicesContent)}</div>
                  <div className={professionalServicesListSectionHyperlink}>
                    <Link
                      to={`/${professionalServicesCtaLink.toLowerCase()}`}>
                      {professionalServicesCtaText}
                    </Link>
                  </div>
                </div>
              )
            }}
            title={professionalServicesListSubheader}
          />
        </div>
        <div>
          <WhatIsComponent
            backgroundColor={'var(--formFields)'}
            image={whyDeimosSectionImage}
            items={whyDeimosSectionContent}
            render={(item, index) => {
              const { whyDeimosLeadText, whyDeimosSubtext, whyDeimosDescription } = item
              return (
                <div
                  className={contentWrapper}
                  key={index.toString()}>
                  <span className={leadTextStyle}>{whyDeimosLeadText}</span>
                  <h2 className={headingColor}>{whyDeimosSubtext}</h2>
                  <span>{parse(whyDeimosDescription)}</span>
                </div>
              )
            }}
          />
        </div>
        <div>
          <CloudNativeClientsComponent
            backgroundColor={'var(--textColor)'}
            customWidth='968px'
            items={ourCloudNativeClientsLogo}
            render={(item, index) => {
              const { clientsLogo } = item
              return (
                <div
                  className={clientsLogoSection}
                  key={index.toString()}
                >
                  <img src={clientsLogo.sourceUrl} />
                </div>
              )
            }}
            title={ourCloudNativeClientsHeader}
          />
        </div>
        <SocialLinks
          hasWhiteBackground
          socialLinks={footerData.socialLinks} />
      </div>
    </>
  )
}

ProfessionalServicesPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
}

export default ProfessionalServicesPage
